<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" v-can:hide.distributor />
        <filter-province
          class="mr-2 mt-2"
          v-model:value="provinsi"
          v-model:region="region"
          v-can:hide.distributor
        />
        <filter-area
          class="mt-2"
          v-model:value="area"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:region="region"
          v-can:hide.distributor
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          class="mr-2 mt-2"
          v-model:value="kabupaten"
          v-model:area="area"
          v-can:hide.distributor
        />
        <filter-lt class="mr-2 mt-2" v-model:value="lt" v-model:distributor="distributor" />
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 2, label: 'Approve ASM' },
            { value: 1, label: 'Approve TSO' },
            { value: 0, label: 'New' },
            { value: 6, label: 'Hapus' },
          ]"
          class="mt-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="status"
        ></a-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="ml-2 mr-2"
          title="Rejected / Hapus"
          type="danger"
          @click="modal2Visible = true"
          v-has-access="['customer-lt.destroy']"
        >
          <i class="fa fa-trash" aria-hidden="true"></i>
          <a-modal
            title="Hapus Toko"
            v-model:visible="modal2Visible"
            @ok="hapus"
            :confirm-loading="loading"
          >
            <p v-if="selectedRowKeys.length === 0">Checklist toko yang akan di hapus</p>
            <p v-else>Anda yakin ingin menghapus toko terpilih?</p>
          </a-modal>
        </a-button>

        <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns.filter(c => !c.hidden)"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #customTitle>
          <span>
            Status
          </span>
        </template>
        <template #nilai="{ text }">
          <span>{{ (text = 'null') }}</span>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #none><span style="display: none;"></span></template>
        <template #status="{ text, record }">
          <a-tooltip>
            <a href="javascript: void(0);" @click="status(record.id)">
              <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
              <a-tag v-else color="grey">Tidak Aktif</a-tag>
            </a>
          </a-tooltip>
        </template>
        <template #action="{record}">
          <span>
            <!-- read unless role distributor -->
            <a-tooltip
              v-if="!hasRoles([ROLE_DISTRIBUTOR])"
              title="Edit Toko">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="editToko(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>

            <a-tooltip title="Lihat Toko">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="lihatToko(record.id)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>

    <a-modal
      v-model:visible="modal3Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modal3Visible = false"
    >
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </template>
      <m-modal ref="modalRef"></m-modal>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, provide, toRefs, computed } from 'vue'
import apiClient from '@/services/axios'
import MModal from './modal'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterLt from '@/components/filter/FilterLt'
import qs from 'qs'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import useUserInfo from '@/composables/useUserInfo'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_DISTRIBUTOR,
} from '@/helpers'

const acolumns = [
  {
    title: 'Tanggal Didaftarkan',
    dataIndex: 'registration_at',
    width: 100,
  },
  {
    title: 'ID Toko',
    dataIndex: 'code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'name',
    width: 130,
  },
  {
    title: 'Kode Distributor Toko',
    dataIndex: 'distributor_code',
  },
  {
    title: 'Nama Distributor Toko',
    dataIndex: 'distributor_name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
    width: 200,
  },
  {
    title: 'Nama Pemilik Toko',
    dataIndex: 'owner',
    width: 150,
  },
  {
    title: 'Region Toko',
    dataIndex: 'region',
    width: 100,
  },

  {
    title: 'Provinsi Toko',
    dataIndex: 'provinsi',
    width: 120,
  },

  {
    title: 'Area Toko',
    dataIndex: 'area',
    width: 100,
  },

  {
    title: 'Kabupaten Toko',
    dataIndex: 'kabupaten',
    width: 200,
  },

  {
    title: 'Kecamatan Toko',
    dataIndex: 'kecamatan',
  },
  {
    title: 'Kelurahan / Desa Toko',
    dataIndex: 'desa',
  },
  {
    title: 'Status Toko',
    title_xls: 'status toko',
    dataIndex: 'status_text',
    width: 90,
  },
  {
    title: 'Status Approval',
    dataIndex: 'status',
  },
  {
    title: 'Kode Large Toko',
    dataIndex: 'lt_code',
    width: 100,
  },
  {
    title: 'Nama Large Toko',
    dataIndex: 'lt_name',
    width: 100,
  },
  {
    title: 'Kode Distributor Large Toko',
    dataIndex: 'distributor_lt_code',
    width: 100,
  },
  {
    title: 'Nama Distributor Large Toko',
    dataIndex: 'distributor_lt_name',
    width: 100,
  },
  {
    title: 'kelengkapan dokumen',
    title_xls: 'kelengkapan dokumen',
    dataIndex: 'kelengkapan_dokumen',
    hidden: true,
  },
  {
    title: 'Action',
    width: 80,
    slots: { customRender: 'action' },
  },
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default defineComponent({
  components: {
    MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterLt,
  },
  setup() {
    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const { isDistributor, vendor_id, regions, areas } = useUserInfo()
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const loading = ref(false)
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const distributor = ref([])
    const area = ref([])
    const modalRef = ref('')

    const status = ref(null)
    const lt = ref([])
    const columns = ref([])
    const data = ref([])
    const q = ref('')
    const searchInput = ref(null)
    const active = ref(null)

    const isPosting = ref(false)
    const formState = ref({
      status_approval: 0,
      foto_ktp: null,
      status_approval: 0,
      wilayah_id: null,
      type: 1,
      vendors: [],
    })

    const errorMessage = ref(null)

    columns.value = acolumns
    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
      regions.value.forEach(item => {
        // region.value.push(item.id)
      })
      areas.value.forEach(item => {
        // area.value.push(item.id)
      })
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const search = () => {
      fetchData()
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        status: status.value,
        distributor: distributor.value,
        q: q.value,
        lt: lt.value,
        status: status.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/customer-lt', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/customer-lt', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `master-data-jaringan-lt_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const visible = ref(false)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    provide('formState', formState)

    const editToko = id => {
      apiClient
        .get(`/api/customer-lt/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal3Visible.value = true
          formState.value.foto = formState.value.foto_toko
          formState.value.title_state = 'Edit Toko'
          formState.value.state = 'edit'
          formState.value.readOnly = false
          apiClient
            .get(`/api/customer-lt/${id}/codes`)
            .then(({ data }) => {
              formState.value.customerCodes = Object.assign([], data.items)
              formState.value.vendorRaw = formState.value.vendors.map(v => ({
                ...v,
                name: `${v.code} - ${v.name}`,
              }))
              formState.value.vendors = formState.value.vendors.map(v => v.id)
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error({ failure: error }))
    }

    const lihatToko = id => {
      apiClient.get(`/api/customer-lt/${id}`).then(response => {
        const data = response.data
        formState.value = data
        modal3Visible.value = true
        formState.value.foto = formState.value.foto_toko
        formState.value.title_state = 'Lihat Toko'
        formState.value.readOnly = true
        apiClient
          .get(`/api/customer-lt/${id}/codes`)
          .then(({ data }) => {
            formState.value.customerCodes = Object.assign([], data.items)
            formState.value.vendorRaw = formState.value.vendors.map(v => ({
              ...v,
              name: `${v.code} - ${v.name}`,
            }))
            formState.value.vendors = formState.value.vendors.map(v => v.id)
          })
          .catch(error => console.error(error))
      })
    }

    const submit = async () => {
      try {
        await modalRef.value.formRef.validate()
      } catch (error) {
        console.log('submit', error)
        return
      }

      if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit Toko',
          content: 'Apakah anda ingin melanjutkan Edit Toko ?',
          onOk() {
            isPosting.value = true

            // const form_data = new FormData()
            // for (var key in formState.value) {
            //   if (key === 'active' || key === 'kelengkapan_dokumen') {
            //     form_data.append(key, formState.value[key] ? 1 : 0)
            //   } else
            //     form_data.append(key, formState.value[key] === null ? '' : formState.value[key])
            // }
            const form_data = serialize({
              ...formState.value,
              vendors: formState.value.vendors.map(id => ({ vendor_id: id })),
            })
            // TODO: validasi
            apiClient
              .post('/api/customer-lt/' + formState.value.id + '?_method=PUT', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.customerCodes
                const promises = []
                codes
                  .filter(c => c.id === null)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const post = apiClient
                      .post('/api/customer-lt/' + vendor_id + '/codes', c)
                      .then(r => {
                        console.log('edit post codes', r)
                      })
                    promises.push(post)
                  })
                codes
                  .filter(c => c.is_deleted)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const _delete = apiClient
                      .delete('/api/customer-lt/' + vendor_id + '/codes/' + c.id)
                      .then(r => {
                        console.log('delete codes', r)
                      })
                    promises.push(_delete)
                  })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data Toko.'),
                )
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal mengupdate!'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      }
    }

    const hapus = () => {
      // modal1Visible.value = false
      const promises = []
      state.loading = true
      state.selectedRowKeys.forEach(k => {
        promises.push(
          apiClient.delete('/api/customer-lt/' + k + '?_method=PUT', {
            active: false,
          }),
        )
      })

      Promise.all(promises)
        .then(() => {
          console.log('completed')
        })
        .finally(err => {
          state.selectedRowKeys = []
          modal2Visible.value = false
          formState.value = data
          fetchData()
          message.success('Berhasil dihapus')
        })
    }

    onMounted(() => {
      fetchData()
    })

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      rowSelection,
      columns,
      data,
      q,
      searchInput,
      startRow,
      region,
      provinsi,
      kabupaten,
      distributor,
      area,
      state,
      ...toRefs(state),
      fetchData,
      lt,
      search,
      modal2Visible,
      modal3Visible,
      setModal1Visible,
      fetchXlsx,
      isPosting,
      formState,
      onSelectChange,
      visible,
      handleTableChange,
      loading,
      modalRef,
      active,
      errorMessage,
      status,
      hapus,
      editToko,
      lihatToko,
      submit,
      // only func roles
      hasRoles,
      ROLE_ADMIN_BK,
      ROLE_DISTRIBUTOR,
    }
  },
})
</script>
<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
</style>
<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
